import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"


class Work extends React.Component {
  render() {
    const siteTitle = "Atul Prd"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Work"
          keywords={[`Blog`, `Product Design`, `Branding`, `UX Design`, `Product Designer`, `UX/UI`, `App Design`, `Brand Identity`]}
        />
        <img style={{ margin: 0, marginTop: 10  }} src="https://res.cloudinary.com/atulprd/image/upload/v1599187149/Atul-Pradhananga-Picture_icudmy.png" alt="Atul Pradhananga"/>
        <h1 style={{ marginTop: 20, marginBottom: 20, textTransform: "none", letterSpacing: 0 }} >Hi there<span role="img" aria-label> 👋 </span></h1>
        <h2 style= {{ lineHeight: 1.3 , marginTop: `10px` }}>
          I'm Atul, Product Designer & UX Researcher
          </h2>
          <p>
          For the last 5 years, I've helped define, design, and launch better user experiences & distinctive brands for MNCs, bootstrapped startups, SaaS products, & everything in between.
          </p>
          
          <p style= {{ marginTop: `30px` }}>
          To briefly introduce myself, I am currently at Seelogic, where I lead the design of new products, from idea to UX research to pixel-perfect execution. Prior to that, I designed brand identities, websites, & marketing materials for various national and international brands at ACT360. And before that, I was the creative director at Technorio, where I crafted the visual direction of the company from ground up. Here's my full <a href={`https://res.cloudinary.com/atulprd/image/upload/v1600149727/Atul_Prd_-_Resume_2020_xtlow9.pdf`}>resume.</a>
          </p>
        
        <p style= {{ marginTop: `30px` }}>
        I focus on Interaction Design, Product Strategy, and Visual Design. I thrive when collaborating on leading cross-org projects at the intersection of business strategy, branding, and product experience.
        </p> 
    <p style= {{ marginBottom: `20px` }}>
    Some of my most successful projects over the years include:
    </p>
  <span/>
  <h3 style= {{ lineHeight: 1.3 , marginTop: `10px`, marginBottom: `10px`}}><a href={`https://www.notion.so/atulprd/Crumbs-Baking-Recipe-App-236a2d1454f848388775155ffc486204`}>Redesigned a fun learning journey & baking experience for the wannabe bakers</a></h3>
  <img style={{ margin: 0, marginTop: 10  }} src="https://res.cloudinary.com/atulprd/image/upload/v1600148232/Crumbs_Hero_Image_auju0n.png" alt="Crumbs App"/>
  <br/>
  <h3 style= {{ lineHeight: 1.3 , marginTop: `50px`, marginBottom: `10px`}}><a href={`https://www.notion.so/atulprd/Concave-App-Enhanced-event-experience-attendee-engagement-for-Bonhill-Group-d62bf0b8cde04002b60fcaf7c6c6a94f`}>Enhanced the event experience & increased attendee engagement for Bonhill</a></h3>
  <img style={{ margin: 0, marginTop: 10  }} src="https://res.cloudinary.com/atulprd/image/upload/v1600148232/Concave-App-UX-Study-Events_kstnkl.png" alt="Concave App"/>
  <br/>
  <h3 style= {{ lineHeight: 1.3 , marginTop: `50px`, marginBottom: `10px`}}><a href={`https://www.behance.net/gallery/85589665/OISHII-Japanese-Restaurant-Sake-Bar`}>Brand strategy, creative direction, & identity design for Oishii — a Japanese restaurant and sake bar</a></h3>
  <img style={{ margin: 0, marginTop: 10  }} src="https://res.cloudinary.com/atulprd/image/upload/v1600152019/Oishii_m0e0kc.jpg" alt="Brand strategy, creative direction, & identity design for Oishii, a Japanese restaurant and sake bar located at the heart of NYC."/>
  <br/>
  <h3 style= {{ lineHeight: 1.3 , marginTop: `50px`, marginBottom: `10px`}}><a href={`https://www.behance.net/gallery/75611073/Chofy-Branding`}>Branding and packaging design for Chofy</a></h3>
  <img style={{ margin: 0, marginTop: 10  }} src="https://res.cloudinary.com/atulprd/image/upload/v1600152024/Chofy_uoplhg.jpg" alt="Branding and packaging design for Chofy, a chocolate brand."/>
  <br/>
    <h2 style= {{ lineHeight: 1.3 , marginBottom: `10px` }}>Featured writings</h2>
    <p>I love learning and sharing my knowledge with the community. Here are some of my most helpful writings.</p>
    <li><b><a href={`https://www.atulprd.com/blog/what-makes-great-brands-great/`}>What Makes Great Brands Great</a></b> – thoughts on building a brand that matters.</li>
    <li><b><a href={`https://www.atulprd.com/blog/design-for-delight/`}>Designing for Delight</a></b> – how to design products that delight the users.</li>
    <li><b><a href={`https://www.atulprd.com/blog/think-like-a%20detective/`}>Think Like a Detective</a></b> — my process for ux research.</li>
    <li><b><a href={`https://www.atulprd.com/blog/you-call-it-writers-block-professionals-call-it-writing/`}>You call it writer's block. Professionals call it writing.</a></b> — a riff on writer's block.</li>
    <li><b><a href={`https://www.atulprd.com/blog/find-products-for-your-customers/`}>Find products for your customers</a></b> — on the importance of discovery phase.</li>
    <li><b><a href={`https://www.atulprd.com/blog/how-to-measure-intangibles/`}>How to measure intangibles?</a></b> — on deconstructing the construct.</li>
    <h2>Let's Chat</h2>
    <p>
    I am currently looking for new job opportunities, and also open to new freelance design projects or contract work. Email me at: <a href={`mailto:hi@atulprd.com`} target="_blank" rel="noopener noreferrer">hi@atulprd.com</a>
    <a href={"mailto:hi@atulprd.com"} target="_blank" rel="noopener noreferrer">
          <Button marginTop="35px">Get in touch</Button>
    </a>
    </p>
    <h2>
    Elsewhere
    </h2>
    <p> 
            You can also find me on — <a href={`https://www.twitter.com/atulprd`} target="_blank" rel="noopener noreferrer"> Twitter</a> · <a href={`https://www.instagram.com/atulprd`} target="_blank" rel="noopener noreferrer"> Instagram </a> · <a href={`https://www.linkedin.com/in/atulpradhananga`} target="_blank" rel="noopener noreferrer"> LinkedIn </a>
        </p>
    <br/>
      </Layout>
    )
  }
}

export default Work
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
